
@mixin social-icon-base-fill(
  $active: $color-royal-one-s50,
  $primary: $color-brand-tertiary,
  $secondary: $color-brand-secondary
) {
  .boa-social--pinterest,
  .boa-social--twitter {
    path:nth-child(1) {
      fill: $primary;
    }

    path:nth-child(2) {
      fill: $secondary;
    }

    &:hover,
    &:focus,
    &:active {
      path:nth-child(1) {
        fill: $active;
      }
    }
  }

  .boa-social--facebook  {
    path:nth-child(1) {
      fill: $primary;
    }

    path:nth-child(2) {
      fill: $secondary;
    }

    &:hover,
    &:focus,
    &:active {
      path:nth-child(1) {
        fill: $active;
      }

      path:nth-child(2) {
        fill: $secondary;
      }
    }
  }

  .boa-social--instagram {
    path {
      fill: $primary;
    }

    &:hover,
    &:focus,
    &:active {
      path {
        fill: $active;
      }
    }
  }

  .boa-social--linkedin {
    path:nth-child(1) {
      fill: $primary;
    }

    path:nth-child(2),
    path:nth-child(3) {
      fill: $secondary;
    }

    &:hover,
    &:focus,
    &:active {
      path:nth-child(1) {
        fill: $active;
      }
    }
  }

  .boa-social--youtube {
    path {
      fill: $primary;
    }

    polygon {
      fill: $secondary;
    }

    &:hover,
    &:focus,
    &:active {
      path {
        fill: $active;
      }
    }
  }
}
